import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Text } from "~/components/common/Typography"
import { Box, Flex } from "reflexbox"
import { css } from "@emotion/core"
import GraphicWithText from "~/components/page/GraphicWithText"
import { mq } from "~/components/common/theme"
import Copyright from "~/components/common/Copyright"

const Page = ({ myPage = 2, mySubpage = 3 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "3.4HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic1: file(name: { eq: "3.4a-ADIDAS-jerseys" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "3.4b-ADIDAS-holdinghands" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "3.4c-ADIDAS-music" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic4: file(name: { eq: "3.4d-ADIDAS-love" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, graphic1, graphic2, graphic3, graphic4 } = data

  const graphics = [
    {
      header: "Use grouping strategies that randomize partners",
      text:
        "Find someone wearing similar shoes as you, line up by birthday, or count off by number.",
      graphic: graphic1,
    },
    {
      header: "Assign warm-up partners who normally wouldn’t work together",
      text:
        "Catchers and outfielders, centers and point guards, different track and field event athletes.",
      graphic: graphic2,
    },
    {
      header:
        "Use your knowledge of players to assign partners with similar interests",
      text: "Girls who love cats or who both play an instrument.",
      graphic: graphic3,
    },
    {
      header: "Use grouping strategies that deepen existing relationships",
      text:
        "Let the girls choose a friend to pair up with and have a conversation.",
      graphic: graphic4,
    },
  ]

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Coaches should help girls make friends."
        image={hero}
        bg={"white"}
      />

      <Infobox uppercase>
        Athlete-to-athlete relationships have important impacts on and off the
        field. Friendships can provide a protective layer for each player.
        Winning is more rewarding with friends, and losing is easier to bear
        with friends.
        <br/> <br/>
        We’ve also established how a strict behavior code can help inform
        athletes on how the program will watch out for their safety and needs.
        <br/> <br/>
        The next step is to make sure that your players understand their
        teammates will also be watching out for them.
      </Infobox>

      <InfoboxDetailed
        headerMaxWidth={"desktop"}
        headerText={"Ways to create prosocial connections"}
        py={6}
      >
        <Box maxWidth={"mediumContent"} mx={"auto"}>
          <Text>
            To do this, you as a coach should work on creating prosocial
            connections by implementing different strategies to see what works
            well for your team.
          </Text>
        </Box>
        <Flex
          maxWidth={"desktop"}
          mx={"auto"}
          justifyContent={"center"}
          alignItems={"start"}
          my={6}
          css={css`
            flex-wrap: wrap;
          `}
        >
          {graphics.map((item, index) => (
            <GraphicWithText
              image={item.graphic}
              width={["100%", "50%", "25%"]}
              px={6}
              key={index}
              mq={mq({
                width: ["50%", "100%"],
              })}
            >
              <Text mt={0}>
                <span
                  css={css`
                    font-family: "AdineuePROBoldWeb";
                    text-transform: uppercase;
                  `}
                >
                  {item.header}
                </span>
              </Text>
              <Text>{item.text}</Text>
            </GraphicWithText>
          ))}
        </Flex>
        <Box px={3} maxWidth={"mediumContent"} mx={"auto"}>
          <Text>
            Sometimes it helps to think about your own friends. Where did you
            meet them? What were the circumstances? How long have you been
            friends?
          </Text>
          <Text>
            You may have one or two best friends that you’ve known for a long
            time. These friends help you through good and bad situations, and
            you’ve done the same for them. As a coach, you can help create
            lasting friendships that will extend far beyond the time your
            athletes spend on your team.
          </Text>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBoldWeb"}
          >
            Remember, it’s not all on you as a coach. You have access to a set
            of helpers. That’s the beauty of a team: the girls can help each
            other feel safe. The sooner you’re able to get the team working on
            things that help everyone feel safe, the easier your job will get.
          </Text>
        </Box>
      </InfoboxDetailed>
      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 2, 3)
